body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-primary: #0296de;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.no-mouse {
  pointer-events: none;
}

.text-link {
  text-decoration: underline;
  color: var(--color-primary);
  cursor: pointer;
}

.text-link:hover {
  opacity: 0.5;
}

.hidden {
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}
