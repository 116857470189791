.login-page {
  margin-top: auto;
  margin-bottom: auto;
}

.login-page .logo {
  text-align: center;
  margin-bottom: 20px;
}

.login-page .logo img {
  width: 180px;
}

.login-page .btn-primary {
  margin: 24px 0;
}

.login-page h4 {
  text-align: center;
  font-weight: bold;
}

/* .forgot-password {
  text-align: right;
}
  
.forgot-password a {
  font-size: 12px;
} */
