.payment-item {
  width: 100%;
  background: white;
  border: 1px solid gray;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 15px;
  cursor: pointer;
}

.payment-item:hover {
  border: 2px solid var(--color-primary);
  padding-top: 14px;
  padding-bottom: 14px;
}
