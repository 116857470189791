#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
  padding: 30px 15px;
}

.header + .main-content {
  background-color: #f4f4f4;
}

.sm-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  width: 100%;
}

.admin-page {
  display: flex;
  flex-direction: column;
}

.admin-form {
  margin-top: auto;
}

.form-title {
  color: #999999;
  text-align: center;
  margin-bottom: 10px;
}

.invalid-text {
  margin: 0;
  color: red;
  font-size: 12px;
}

.btn-block {
  display: block;
  width: 100%;
  border-radius: 20px;
  border-radius: 20px!important;
}

.btn-block+.btn-block {
  margin-top: 0.5rem;
}

.btn-outline-primary {
  color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.btn-outline-primary:hover {
  background-color: var(--color-primary) !important;
  color: #fff !important;
}

.btn-primary {
  background-color: var(--color-primary) !important;
}

.btn-back-link {
  display: flex;
  width: fit-content;
  text-decoration: none;
}

.btn-icon {
  display: flex;
  align-items: center;
}

.btn-icon > svg {
  margin-right: 6px;
}

.btn-icon.right > svg {
  margin-right: 0;
  margin-left: 6px;
}
