.header {
  text-align: center;
  padding: 15px;
}

.header .logo {
  width: auto;
  height: 50px;
}

.header .main-content {
  background-color: #F4F4F4;
}
