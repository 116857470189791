.signup-page {
  margin-top: auto;
  margin-bottom: auto;
}

.signup-page .btn-primary {
  margin: 24px 0;
}

.signup-page .terms-and-privacy {
  text-align: center;
  font-size: 12px;
}
