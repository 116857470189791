.settings-list {
  background-color: white;
  border: 1px solid #707070;
  margin: 20px 0;
  padding: 0;
}

.settings-list li {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin: 0 8px;
  cursor: pointer;
}

.settings-list li+li {
  border-top: 1px solid #999;
}

.settings-list li:hover a, .settings-list li:hover svg {
  color: var(--color-primary);
}

.settings-list li svg {
  color: #999;
}

.settings-list li a {
  text-decoration: none;
  color: black;
  flex: 1;
  padding: 10px 0;
}
